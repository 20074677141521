:root {
	--text-color: white;
	--text-dark-color: #202020;
	--header-height: 100px;
	--burger-width: 40px;
	--font-headings: Georgia, serif;

	--bcg-green: #acb7ae;
	--bcg-lightgreen: #a6b3b3;
	--bcg-lightbrown: #b0a7a4;
	--bcg-lightblue: #a3abb1;

	--bcg-loaderblue: #758a94;
	--bcg-loaderbluedark: #566c76;

	--loader-width: 27.36vw;
	--loader-height: 50vh;

	--v-spacing: 10vh;
	--h-gutter: 6.6vw;

	--easing: cubic-bezier(0.5, 0.7, 0.4, 1);

	--text-dark: #191919;
	--text-light: white;
	--color-text: #191919;
	--color-bg: #f0e3d3;
	--color-link: #191919;
	--color-link-hover: #292929;
	--color-content-title-sub: #191919;
	--cursor-stroke: #191919;
	--cursor-fill: none;
	--cursor-stroke-width: 1px;
}

*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	font: 16px "Source Sans Pro", Helvetica, Helvetica Neue, Arial, sans-serif;
	color: var(--text-dark-color);
	font-size: 18px;
	font-weight: 400;
	margin: 0;

	background-color: var(--color-bg);
	font-family: bilo, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
		Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
ul,
ol {
	list-style-type: none;
}

a {
	/* text-decoration: underline; */
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover,
a:focus {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

@media (min-width: 768px) {
	section {
		min-height: 100vh;
	}
}

h1 {
	font-family: "bely-display", sans-serif;
	font-weight: 400;
}

.grid {
	display: grid;
}

.navbar--home {
	background-color: transparent !important;
	border-bottom: none !important;
}

.navbar--home .navbar__links {
	justify-content: end;
}

.navbar--home .nav-open {
	font-family: "bilo", sans-serif;
	font-weight: 600;
	position: absolute;
	top: 2rem;
	right: 3rem;
	font-size: clamp(1.15rem, 4vw, 1.4rem);
}

.navbar--home .nav-open li:after {
	content: "";
	display: block;
	background-color: #191919;
	height: 0.1rem;
	width: 100%;
}

/* .navbar--home .nav-open li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.15rem;
  border: 1px solid #191919;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  transform: translateY(-50%);
  transform: translateY(50%);
} */

/* NEW NAV STYLES */
.slide-nav {
	height: 100vh;
	width: 100%;
	background-color: #191919;
	font-family: bely-display, sans-serif;
}

.slide-nav__wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	height: 100%;
}

.nav-close {
	display: grid;
	place-content: center end;
	margin-right: 10%;
	color: #fff;
	grid-column: 1 / 13;
}

.slide-nav__wrapper .ul {
	display: grid;
	grid-column: 3 / 13;
	margin-top: 5%;
	margin-bottom: 5%;
}

.slide-nav__wrapper .ul li,
.slide-nav__wrapper .social li {
	list-style-type: none;
}

.slide-nav__wrapper .ul li {
	font-size: clamp(2.5rem, 4vw, 4rem);
	margin-bottom: 1.5rem;
}

.slide-nav__wrapper li a {
	color: #fff;
	text-decoration: none;
}

.slide-nav__wrapper .slide-nav__links {
	grid-column: 3 / 13;
}

.slide-nav__wrapper .social ul {
	display: flex;
	/* margin-bottom: 1.5rem; */
}

.slide-nav__wrapper .social .social-li {
	width: 2rem;
}

.slide-nav__wrapper .social .social-li:first-of-type {
	margin-right: 2rem;
}

.slide-nav__wrapper .supporters {
	grid-column: 1 / 13;
}

.slide-nav__wrapper .supporters__list {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1.5rem;
}

.slide-nav__wrapper .supporters__title {
	grid-column: 1 / 13;
}

.slide-nav__wrapper .supporters__list li {
	color: #fff;
	list-style-type: none;
}

.slide-nav__wrapper
	.supporters__list
	li:not(.slide-nav__wrapper .supporters__list:last-child) {
	/* margin-bottom: 1rem; */
}

.slide-nav__wrapper .supporters__melon {
	grid-column: 1 / 7;
}

@media (min-width: 768px) {
	.slide-nav__wrapper .supporters__melon {
		grid-column: 1 / 5;
	}
}

@media (min-width: 992px) {
	.slide-nav__wrapper .supporters__melon {
		grid-column: 1 / 4;
	}
}

@media (min-width: 1200px) {
	.slide-nav__wrapper .supporters__melon {
		grid-column: 1 / 3;
	}
}

.slide-nav__wrapper .melon-logo {
	width: 100%;
	height: auto;
}

.slide-nav__wrapper .vanderbilt-logo {
	grid-column: 7 / 13;
}

@media (min-width: 768px) {
	.slide-nav__wrapper .vanderbilt-logo {
		grid-column: 5 / 13;
	}
}

@media (min-width: 992px) {
	.slide-nav__wrapper .vanderbilt-logo {
		grid-column: 4 / 13;
	}
}

@media (min-width: 1200px) {
	.slide-nav__wrapper .vanderbilt-logo {
		grid-column: 3 / 13;
	}
}

.supporters img,
.supporters svg {
	width: 100%;
	max-width: 200px;
}

.slide-nav__wrapper .melon-logo path {
	fill: #fff;
}

/* NAVIGATION STYLES */
.navbar__link {
	font-family: bilo, sans-serif;
	font-weight: 400;
}
.navbar__link--themes {
	display: none;
}

@media (min-width: 768px) {
	.navbar__link--themes {
		display: initial;
	}
}
.slide-nav-open {
	overflow: hidden !important;
}

/* .slide-nav .button {
  position: absolute;
  top: 50px;
  right: 100px;
  text-decoration: none;
  color: black;
  right: 100px;
  font-size: 15px;
  font-weight: 500;
} */
.slide-nav.menu {
	font-family: bely-display, sans-serif;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: #191919;
	transform: translateX(-100%);
	z-index: 2000;
}
.slide-nav.menu .button {
	color: white;
	font-family: "bilo", sans-serif;
	font-size: 1.15rem;
}
/* 
.slide-nav .ul {
  position: absolute;
  left: 20%;
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: left;
  height: 100vh;
  flex-direction: column;
}
.slide-nav .ul li {
  margin: 20px 0;
  z-index: 2;
}
.slide-nav .ul li a {
  text-decoration: none;
  color: white;
  transition: 1s;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 500;
  mix-blend-mode: difference;
}
.slide-nav .social {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 80px;
  width: 100%;
}
.slide-nav .social ul {
  margin-left: 50px;
  display: flex;
}
.slide-nav .social ul li {
  width: 24px;
  margin: 0px 10px;
}
.slide-nav .social ul li a {
  text-decoration: none;
  font-family: "Monument";
  font-size: 18px;
  text-transform: uppercase;
  font-weight: lighter;
  padding: 1rem;
}

.slide-nav .social ul li:first-child {
  margin-right: 1rem;
}

.slide-nav .social ul li img {
  fill: #fff !important;
} */
.slide-nav .bg {
	display: none;
}

@media (min-width: 768px) {
	.slide-nav .bg {
		display: block;
		position: absolute;
		width: 250px;
		height: 300px;
		pointer-events: none;
		/* mix-blend-mode: difference;
    filter: grayscale(1); */
	}
}
.slide-nav .bg1 {
	top: 50px;
	left: 40%;
	background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282304/Website/images/m_home_iakibn.jpg")
		no-repeat 50% 50%;
	background-size: cover;
	opacity: 0;
	transform: translateY(-50%);
	transition: 1s ease-in-out;
}
.slide-nav .bg2 {
	top: 100px;
	left: 40%;
	background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282305/Website/images/m_themes_vndnis.jpg")
		no-repeat 50% 50%;
	background-size: cover;
	opacity: 0;
	transform: translateY(-50%);
	transition: 1s ease-in-out;
}
.slide-nav .bg3 {
	top: 250px;
	left: 40%;
	background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282297/Website/images/m_about_uza4co.jpg")
		no-repeat 50% 50%;
	background-size: cover;
	opacity: 0;
	transform: translateY(-50%);
	transition: 1s ease-in-out;
}
.slide-nav .bg4 {
	top: 375px;
	left: 40%;
	background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282305/Website/images/m_team_qtqhhx.jpg")
		no-repeat 50% 50%;
	background-size: cover;
	opacity: 0;
	transform: translateY(-50%);
	transition: 1s ease-in-out;
}

.slide-nav .bg5 {
	top: 425px;
	left: 40%;
	background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1695076464/logo-o_wzeima.svg")
		no-repeat 50% 50%;
	background-size: cover;
	opacity: 0;
	transform: translateY(-50%);
	transition: 1s ease-in-out;
}
.slide-nav .li1:hover ~ .bg1,
.slide-nav .li2:hover ~ .bg2,
.slide-nav .li3:hover ~ .bg3,
.li4:hover ~ .bg4,
.li5:hover ~ .bg5 {
	opacity: 1;
	transform: translateY(0%);
	transition: 1s ease-in-out;
}
.slide-nav .ul li a:hover {
	transition: 1s;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke: 1px white;
	/* font-style: italic; */
}

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f1e4d5;
	z-index: 1000;
	border-bottom: 1px solid #8b8b8b;
}

.navbar .container {
	padding: 0.5rem;
}

.navbar__links {
	display: flex;
	justify-content: space-between;
	padding: 0;
	margin: 0;
}

.navbar__link {
	padding: 1rem;
	text-decoration: none;
	cursor: pointer;
}

/* ------------------------- */

.container {
	width: 100%;
	max-width: 1320px;
	margin-left: auto;
	margin-right: auto;
	padding: 1rem;
}

.container-xl {
	height: 100%;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding: 1rem;
}

.filter {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282313/Website/images/noise-bg_sj0tvw.jpg");
	background-repeat: repeat;
	background-size: auto;
	opacity: 0.1;
	pointer-events: none;
	z-index: 20000;
}

.ball {
	width: 50px;
	height: 50px;
	position: fixed;
	top: 0;
	left: 0;
	border: 1px solid var(--cursor-stroke);
	border-radius: 50%;
	pointer-events: none;
	z-index: 10000;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: "";
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.cursor {
	display: none;
}

@media screen and (min-width: 53em) {
	.content {
		height: 100%;
		justify-content: center;
		max-height: none;
	}
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		z-index: 3000;
	}
	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
	}
}

/* =Reveal Gallery */
.reveal-gallery {
	width: 100%;
	height: calc(100vh - 72px);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: var(--color-text);
	position: relative;
	z-index: 1;
	margin-top: 72px;
}

@media (min-width: 768px) {
	.reveal-gallery {
		flex-direction: row;
	}
}

.rg__column {
	flex-grow: 1; /* equivalent to 33.33% */
	padding: 0 var(--h-gutter);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	text-decoration: none;
}

.rg__column--inner {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (min-width: 768px) {
	.rg__column--inner {
		display: unset;
		align-items: unset;
		justify-content: unset;
	}
}
.rg__text--heading h3 {
	font-size: 7vw;
	line-height: 1.5;
}
.rg__text--heading span {
	font-size: 5vw;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.rg__text--heading h3 {
		font-size: 3.6vw;
		line-height: 1.192307692;
	}
	.rg__text--heading span {
		font-size: 2vw;
		opacity: 0.5;
	}
}
.rg__text--heading h3,
.rg__text--heading span,
.chapter {
	font-family: var(--font-headings);
	font-weight: normal;
}
.rg__text {
	z-index: 1;
	position: relative;
}

@media (min-width: 768px) {
	.rg__text {
		max-width: 17vw;
		z-index: 1;
		position: relative;
	}
}
.rg__text--copy,
.stage__text p {
	font-weight: 300;
	letter-spacing: 0.03vw;
}
/* .rg__column.left {background-color:var(--bcg-lightgreen)}
.rg__column.center {background-color: var(--bcg-lightbrown)}
.rg__column.right {background-color:var(--bcg-lightblue)} */
.rg__divider {
	height: 1px;
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	background-color: rgba(0, 0, 0, 0.2);
}

.rg__cta__wrapper--mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (min-width: 768px) {
	.rg__cta__wrapper--mobile {
		display: none;
	}
}

.rg__cta__underline--mobile {
	height: 1px;
	width: 50px;
	background-color: rgba(0, 0, 0, 0.5);
}

.rg__cta__text--mobile {
	font-size: 1rem;
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 0.15rem;
}

@media (min-width: 768px) {
	.rg__divider {
		display: none;
	}

	.rg__column::before {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		content: "";
		width: 1px;
		height: 20vh;
		transform: translateY(-50%);
		background-color: var(--color-text);
		opacity: 0.1;
	}
}

.rg__image {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #000000;
	pointer-events: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* transform: translateY(-100%);
  transition: transform 0.45s var(--easing); */
}
.rg__image img {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	object-position: 50% 50%;
	opacity: 0.8;
}
.rg__image--mask {
	height: 100%;
	overflow: hidden;
	/* transition: transform 0.45s var(--easing);
  transform: translateY(100%); */
}
.rg__image img {
	opacity: 0.6;
}
.rg__text--copy {
	position: absolute;
	overflow: hidden;
}
.rg__text--mask {
	overflow: hidden;
}

/* =Main */
main {
	z-index: 1;
	position: relative;
}
section {
	position: relative;
}
.with-padding {
	padding: var(--v-spacing) 0;
}
.with-padding-bottom {
	padding-bottom: var(--v-spacing);
}
.chapter {
	position: absolute;
	left: var(--h-gutter);
	top: var(--v-spacing);
	z-index: 2;
	color: var(--text-color);
	font-size: 1.6vw;
}
.chapter span {
	opacity: 0.4;
}

.section--hero {
	display: flex;
	align-items: center;
}

.d-flex {
	display: flex;
}

.align-items-center {
	align-items: center;
}

.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.hero {
	display: grid;
	min-height: 90vh;
	gap: 2rem;
	align-items: center;
}

.grid-col-2 {
	grid-template-columns: 1fr 1fr;
}

@media (min-width: 768px) {
	.hero {
		height: 90vh;
	}
	.grid-col-md-2 {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 768px) {
	.mk-hero__image--mobile {
		display: none;
	}
}

.mk-hero__image {
	display: none;
}

@media (min-width: 768px) {
	.mk-hero__image {
		display: block;
	}
}

.mk-hero__image path:not(.cls-1, .cls-2) {
	stroke-width: 1px;
	stroke: #666;
}
.mk-hero__image .cls-1 {
	stroke-width: 3px;
}
.mk-hero__image .cls-1,
.mk-hero__image .cls-2 {
	fill: none;
	stroke: #666;
	stroke-miterlimit: 10;
}
.mk-hero__image .cls-2 {
	stroke-width: 2px;
}

.hero__image {
	display: flex;
	justify-content: center;
}
.hero__image {
	max-height: 70vh;
}

.hero__title {
	text-align: center;
	font-size: clamp(3rem, 7.5vw, 8rem);
	margin-bottom: 1rem;
}

.public-health-map-section {
	display: none;
}

@media (min-width: 768px) {
	.public-health-map-section {
		min-height: unset;
	}
}

.public-health_icon {
	height: 50px;
	margin-right: 1rem;
}

.public-health-section-mobile {
	padding-bottom: 4rem;
}

.public-health-section-mobile .icon_plus {
	height: 1.25rem;
	fill: #292929;
}

.public-health-section-mobile .locations_list li {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.public-health-section-mobile .locations_list li > div:nth-child(2) {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.public-health-hero {
	height: 40vh;
	min-height: 350px;
	margin-top: 6rem;
}

.divider {
	width: 100%;
	height: 1px;
	background-color: #9e9797;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

@media (min-width: 768px) {
	.public-health-hero {
		min-height: inital;
		height: initial;
		margin-top: 0;
	}
}

@media (min-width: 768px) {
	.public-health-map-section {
		display: block;
	}
	.public-health-section-mobile {
		display: none;
	}
}
.public-health-hero-description {
	margin-left: 0 !important;
}

.hero__description {
	text-align: center;
	font-size: clamp(1rem, 2.3vw, 1.5rem);
	margin-left: 3rem;
}

.mk-hero-title-span {
	margin-left: 3rem;
}

.year {
	display: grid;
	gap: 2rem;
	grid-template-columns: 1fr;
}

@media (min-width: 768px) {
	.year {
		grid-template-columns: 1fr 1fr;
	}

	.year--1854 {
		grid-template-columns: 1fr 3fr;
	}
}

@media (min-width: 1200px) {
	.year--1854 {
		grid-template-columns: 1fr 1fr;
	}
}

.section--year-num-1856 .year-text {
	order: 1;
}

.section--year-num-1856 .year-num-1956 {
	order: 0;
}

@media (min-width: 768px) {
	.section--year-num-1856 .year-text {
		order: 0;
	}

	.section--year-num-1856 .year-num-1956 {
		order: 1;
	}

	.year--1856 {
		grid-template-columns: 2fr 1fr;
	}
}

@media (min-width: 1200px) {
	.year--1856 {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 768px) {
	.year--1867 {
		grid-template-columns: 1fr 3fr;
	}
}

@media (min-width: 1200px) {
	.year--1867 {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 768px) {
	.year--1871 {
		grid-template-columns: 1fr 3fr;
	}
}

@media (min-width: 1200px) {
	.year--1871 {
		grid-template-columns: 1fr 1fr;
	}
}

/* .year-num {
  position: relative !important;
} */

.year-num__stroke {
	color: transparent;
	font-size: clamp(7rem, 14vw, 12rem);
	margin: 0;
	-webkit-text-stroke: 2px #191919;
}

@media (min-width: 768px) {
	.year-num__stroke {
		color: transparent;
		margin: 0;
		margin-top: -4rem;
		-webkit-text-stroke: 2px #191919;
	}
	.year-num__stroke.right {
		text-align: right;
	}
}

.year-text {
	font-size: clamp(1rem, 4vw, 1.5rem);
}

.year__image img {
	width: 100%;
}

.timeline__quote {
	font-size: clamp(1.2rem, 4vw, 4rem);
	font-family: "bely", sans-serif;
	font-weight: 600;
	font-style: italic;
	text-align: center;
	margin-bottom: 0;
}

.timeline__quote__person {
	font-size: clamp(1.2rem, 4vw, 3rem);
	font-family: "bely", sans-serif;
	font-weight: 600;
	font-style: italic;
	text-align: center;
}

@media (min-width: 768px) {
	.timeline__quote-wrapper {
		min-height: 100vh;
	}
}

.section--scrolling-headers {
	font-style: italic;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;
	margin-top: 4rem;
	margin-bottom: 4rem;
}

@media (min-width: 768px) {
	.section--scrolling-headers {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.timeline__scrolling-header:nth-of-type(1) {
	font-size: 2.2rem;
}
.timeline__scrolling-header:nth-of-type(2) {
	font-size: 1.184rem;
}

@media (min-width: 768px) {
	.section--scrolling-headers {
		padding: 0;
	}
	.section--scrolling-headers {
		overflow-x: hidden;
		font-style: italic;
	}

	.scrolling-headers__wrapper {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.timeline__scrolling-header {
		white-space: nowrap;
	}

	.timeline__scrolling-header:nth-of-type(1) {
		font-size: 8rem;
		transform: translate3d(100%, 0, 0);
		margin: 0;
	}
	.timeline__scrolling-header:nth-of-type(2) {
		font-size: 3.158rem;
		transform: translate3d(-200%, 0, 0);
		margin: 0;
	}
}

@media (min-width: 992px) {
	.timeline__scrolling-header:nth-of-type(1) {
		font-size: 11rem;
	}
	.timeline__scrolling-header:nth-of-type(2) {
		font-size: 4.342rem;
	}
}

@media (min-width: 1200px) {
	.timeline__scrolling-header:nth-of-type(1) {
		font-size: 14rem;
	}
	.timeline__scrolling-header:nth-of-type(2) {
		font-size: 526.5rem;
	}
}

@media (min-width: 1400px) {
	.timeline__scrolling-header:nth-of-type(1) {
		font-size: 19rem;
	}
	.timeline__scrolling-header:nth-of-type(2) {
		font-size: 7.5rem;
	}
}

.section--timeline__quote {
	margin-bottom: 8rem;
}

@media (min-width: 768px) {
	.section--timeline__quote {
		position: relative;
		min-height: 100vh;
		margin-bottom: unset;
		padding-top: 8rem;
	}
}

.section--timeline__quote--pai-manoel .timeline__quote,
.section--timeline__quote--diseases-war .timeline__quote {
	font-size: clamp(2rem, 5vw, 5.5rem);
}

.section--timeline__quote .open-quote {
	display: block;
	margin-right: auto;
	width: 12vw;
	margin-bottom: 3vw;
}

.section--timeline__quote .close-quote {
	display: block;
	margin-left: auto;
	width: 12vw;
	margin-top: 3vw;
}

.recife-map {
	width: 100%;
	z-index: 1;
}

.ph-location {
	fill: #f17c57fa;
}

.ph-location__group {
	position: relative;
	cursor: pointer;
}

.ph-location--health-council {
	transform: translate(57.4%, 66.5%);
}

.ph-location--hospital-lazaros {
	transform: translate(96%, 59%);
}

.ph-location--cemetary {
	transform: translate(74.75%, 46.5%);
}

.ph-location--nogueira {
	transform: translate(8.25%, 73%);
}

.ph-location--port {
	transform: translate(57%, 76%);
}

.pulse {
	animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
	0% {
		transform: scale(0);
		opacity: 1;
	}

	50% {
		opacity: 0.8;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

/* Contact Page */

.section--contact {
	margin-top: 6rem;
	margin-bottom: 6rem;
}

.section--contact .contact__header {
	margin-bottom: 2rem;
}

@media (min-width: 768px) {
	.section--contact .contact__header {
		margin-bottom: 4rem;
	}
}

.section--contact .contact__header h1 {
	font-size: clamp(3rem, 6vw, 6rem);
	margin-bottom: 0;
}

.section--contact .contact__header p {
	font-size: clamp(1.2rem, 2.5vw, 1.75rem);
}

.section--contact .contact {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1rem;
}

.section--contact .contact__header {
	grid-column: 1 / 13;
}

@media (min-width: 768px) {
	.section--contact .contact__header {
		grid-column: 1 / 8;
	}
}

.section--contact .contact__info {
	grid-column: 1 / 13;
	margin-bottom: 2rem;
}

@media (min-width: 992px) {
	.section--contact .contact__info {
		grid-column: 1 / 6;
		margin-bottom: 0;
	}
}

.section--contact .contact__info h2 {
	margin-top: 0;
}

.section--contact .contact__form {
	grid-column: 1 / 13;
}

@media (min-width: 992px) {
	.section--contact .contact__form {
		grid-column: 7 / 13;
	}
}

.form-control input,
.form-control textarea {
	min-height: 3.5rem;
	width: 100%;
	border-radius: 0.25rem;
	border: none;
	padding: 0.75rem;
	font-size: 1.15rem;
	margin-top: 0.5rem;
}

.form-control input:focus,
.form-control textarea:focus {
	outline: solid 1px #f1933e;
}

.form-control:not(.form-control:last-child) {
	margin-bottom: 1.5rem;
}

.contact__form .btn--submit {
	background-color: #fff;
	font-size: 1.2rem;
	padding: 0.75em 2em;
	height: 3.5rem;
	border: 1px solid #191919;
	cursor: pointer;
	border-radius: 0.25rem;
	transition: 0.3s;
	width: 100%;
}

@media (min-width: 768px) {
	.contact__form .btn--submit {
		width: initial;
	}
}

.contact__form .btn--submit:hover,
.contact__form .btn--submit:focus {
	box-shadow: 4px 4px 4px rgba(25, 25, 25, 0.2);
}

.form-control .feedback {
	display: none;
	padding: 0.5rem 0.5rem 0.5rem 0;
}
.form-control.error .feedback {
	display: block;
}

.form-control.error input,
.form-control.error textarea {
	outline: 1px solid rgb(206, 1, 1);
}

.form-control.error .feedback {
	color: rgb(206, 1, 1);
}

.form-control label {
	margin-bottom: 0.5rem;
}

.form-control--notes {
	display: none;
}

.form-message.success {
	color: #198754;
	padding-left: 0.5rem;
	margin-top: 0.5rem;
	font-weight: bold;
}

.contact__form .form-message {
	margin-bottom: 1.5rem;
}

.contact__social img {
	width: 32px;
}

.contact__email a {
	text-decoration: none;
	font-size: 1.2rem;
}
.contact__social a:first-child {
	margin-right: 1.25rem;
}

/* Team Page */
.section--team {
	padding-top: 8rem;
	padding-bottom: 4rem;
}

.section--team .team__header {
	margin-bottom: 4rem;
}

.section--team .team__header h1 {
	font-size: clamp(3rem, 6vw, 6rem);
	margin-top: 0;
	margin-bottom: 2rem;
}

.section--team .team__header p {
	font-size: clamp(1.2rem, 2.5vw, 1.75rem);
	margin-top: 0;
}

.section--team .team__member h2 {
	font-size: clamp(1.25rem, 5vw, 3rem);
	margin: 0;
}

.section--team .team__member p {
	font-style: italic;
	font-size: clamp(1rem, 5vw, 1.5rem);
	margin-left: 1rem;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.section--team .team__member h3 {
	font-size: clamp(1rem, 5vw, 2rem);
	margin-top: 0;
	margin-right: 1rem;
	padding-left: 1rem;
	display: inline-block;
}

.section--team .team__member {
	margin-bottom: 2rem;
}

.section--team .team {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1rem;
}

.section--team .team__header {
	grid-column: 1 / 13;
}

@media (min-width: 768px) {
	.section--team .team__header {
		grid-column: 1 / 8;
	}
}

.section--team .team__members {
	grid-column: 1 / 13;
}

.member__links {
	display: inline-block;
}

.member__links a {
	padding: 1rem;
}

.member__links img {
	max-width: 24px;
	width: 100%;
	display: inline-block;
}

/* SLIDE INFO STYLES */
.modal-open {
	overflow: hidden;
}

#modalBackdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 9998;
}

#modal {
	position: fixed;
	top: 0;
	right: -100%;
	min-width: 300px;
	width: 90%;
	max-width: 600px;
	height: 100%;
	background-color: #fafafa;
	display: none;
	z-index: 9999;
	overflow-y: auto;
	transition: right 0.3s ease-out;
	box-shadow: -4px 0px 24px rgba(25, 25, 25, 0.1);
}

/* @media (min-width: 768px) {
  #modal {
    width: 300px;
  }
} */

#modalContent {
	padding: 1rem;
	margin-top: 2.5rem;
}

#closeButton {
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	font-size: 2rem;
	background: none;
	border: none;
	cursor: pointer;
}

.modal_image {
	width: 100%;
	height: auto;
	margin-bottom: 2rem;
	display: none;
}

.modal_title {
	text-align: center;
	font-size: clamp(2rem, 4vw, 3rem);
	margin-bottom: 0.5rem;
}

.modal_text p {
	font-size: clamp(1rem, 2vw, 1.25rem) !important;
}

.modal_text p:not(:last-child) {
	margin-bottom: 1rem;
}

.slide-info__close-wrapper {
	text-align: right;
	padding: 1rem 1rem 2rem 1rem;
}

.slide-info .close {
	display: inline-block;
	padding: 1rem;
	text-decoration: none;
	color: black;
	font-size: 15px;
	font-weight: 500;
}
.slide-info {
	padding: 1rem;
	/* font-family: bely-display, sans-serif; */
	position: fixed;
	top: 0;
	right: 0;
	min-width: 300px;
	width: 90%;
	max-width: 600px;
	height: 100vh;
	background-color: #fafafa;
	transform: translateX(120%);
	z-index: 2000;
	overflow-y: scroll;
	box-shadow: -4px 0px 24px rgba(25, 25, 25, 0.1);
}

.slide-info__image {
	width: 100%;
	margin-bottom: 1rem;
}

.slide-info__title {
	text-align: center;
	font-size: clamp(2rem, 4vw, 3rem);
}

/* ---------------- */

.fill--transparent {
	fill: transparent;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mt-3 {
	margin-top: 1rem;
}

/* MEDICAL KNOWLEDGE STYLES */
.theme__hero {
	padding-top: 8rem;
	/* margin-bottom: 8rem; */
}
@media (min-width: 768px) {
	.theme__hero {
		padding-top: unset;
	}
}
.year-image {
	width: 100%;
}

.year-image__wrapper figure {
	margin: 0;
}

.year-image__wrapper figcaption {
	font-size: 1.25rem;
}

.italic {
	font-style: italic;
}

.text-small {
	font-size: 1rem;
}

.year-text p:last-of-type {
	margin-bottom: 3rem;
}

/* Theme Lead In Section */
.theme-lead-in__link {
	text-decoration: none;
}
.theme-lead-in__wrapper {
	width: 100%;
	opacity: 0;
	margin-bottom: 4rem;
}

.theme-lead-in {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.theme-lead-in__image {
	width: 100%;
}

.theme-lead-in__next {
	opacity: 0;
	text-align: center;
	font-family: bely-display, sans-serif;
	font-size: clamp(1rem, 4vw, 2rem);
	margin-top: 0;
}

/* CHOLERA EPIDEMIC STYLES */
.cholera-hero-section {
	display: flex;
	align-items: center;
	margin-bottom: 4rem;
}
@media (min-width: 768px) {
	.cholera-hero-section {
		margin-bottom: intial;
	}
}
.cholera {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
}

.cholera__image__wrapper figure {
	margin: 0;
	text-align: center;
}

.cholera__image {
	width: 100%;
}

.cholera__text {
	font-size: 1.5rem;
}

.cholera__text h2 {
	margin-top: 0;
}

.cholera__text p:last-of-type {
	margin-bottom: 3rem;
}

.hero__image--cholera {
	max-width: 500px;
}

.impact__image {
	width: 100%;
}

.impact__wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1rem;
}

.impact__info {
	grid-column: 1 / 13;
	margin-bottom: 3rem;
}

@media (min-width: 768px) {
	.impact__info {
		grid-column: 1 / 8;
		margin-bottom: 3rem;
	}
}

.impact__info__title {
	font-size: clamp(2rem, 4vw, 6rem);
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: hidden;
}

.impact__info__title span {
	display: inline-block;
}

@media (min-width: 768px) {
	.impact__info__title span {
		transform: translateY(100%);
	}
}

.impact__info__description {
	font-size: clamp(1rem, 4vw, 2rem);
}

.impact__image__wrapper-1 {
	grid-column: 1 / 13;
	margin-bottom: 4rem;
}

@media (min-width: 768px) {
	.impact__image__wrapper-1 {
		grid-column: 1 / 10;
		margin-bottom: 8rem;
		overflow: hidden;
	}
}

.impact__info__description-2 {
	grid-column: 1/ 13;
}

@media (min-width: 768px) {
	.impact__info__description-2 {
		grid-column: 1/ 7;
		padding-top: 10%;
	}
}

.impact__image__wrapper-2 {
	grid-column: 1 / 13;
	margin-bottom: 4rem;
}

@media (min-width: 768px) {
	.impact__image__wrapper-2 {
		grid-column: 8 / 13;
		margin-bottom: 8rem;
		overflow: hidden;
	}
}

.impact__info__description-3 {
	grid-column: 1 / 13;
	text-align: center;
	font-size: clamp(1.25rem, 4vw, 2rem);
	margin: 0;
	overflow: hidden;
	line-height: 2rem;
}

@media (min-width: 768px) {
	.impact__info__description-3 {
		grid-column: 3 / 11;
	}
}

.impact__info__description-3 span {
	display: inline-block;
}

@media (min-width: 768px) {
	.impact__info__description-3 span {
		transform: translateY(100%);
	}
}

.impact__info__description-1 {
	overflow: hidden;
	margin: 0;
}

.impact__info__description-1 span {
	display: inline-block;
}

@media (min-width: 768px) {
	.impact__info__description-1 span {
		transform: translateY(100%);
	}
}

/* -------------------------- */

.transmission__wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1rem;
}

.transmission__info {
	grid-column: 1 / 13;
}

@media (min-width: 768px) {
	.transmission__info {
		grid-column: 7 / 13;
	}
}

.transmission__image__wrapper {
	grid-column: 1 / 13;
}

@media (min-width: 768px) {
	.transmission__image__wrapper {
		grid-column: 1 / 6;
		margin-top: -10rem;
		overflow: hidden;
	}
}

.transmission__info__title {
	margin-top: 0;
	margin-bottom: 1rem;
	font-size: clamp(2rem, 4vw, 6rem);
	overflow: hidden;
}

.transmission__info__title span {
	display: inline-block;
}

@media (min-width: 768px) {
	.transmission__info__title span {
		transform: translateY(100%);
	}
}

.transmission__info__description {
	font-size: clamp(1rem, 4vw, 2rem);
}

.transmission__info__description-1 {
	overflow: hidden;
	margin: 0;
}

.transmission__info__description-1 span {
	display: inline-block;
}

@media (min-width: 768px) {
	.transmission__info__description-1 span {
		transform: translateY(100%);
	}
}

.transmission__image {
	width: 100%;
}

.transmission__description-2 {
	grid-column: 1 / 13;
	font-size: clamp(1rem, 4vw, 2rem);
	margin: 0;
	overflow: hidden;
}

@media (min-width: 768px) {
	.transmission__description-2 {
		grid-column: 7 / 13;
	}
}

.transmission__description-2 span {
	display: inline-block;
}

@media (min-width: 768px) {
	.transmission__description-2 span {
		transform: translateY(100%);
	}
}

.transmission__wrapper-2 {
	gap: 0;
}

.cholera-reaper__wrapper {
	display: none;
	overflow: hidden;
	margin-top: 4rem;
	margin-bottom: 4rem;
}

@media (min-width: 768px) {
	.cholera-reaper__wrapper {
		display: block;
	}
}

.cholera-reaper__image {
	width: 700px;
	transform: translateX(-100%);
}

/* ------------------------- */

.exposure__wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1rem;
}

.exposure__info {
	grid-column: 1 / 13;
	margin-bottom: 4rem;
}

@media (min-width: 768px) {
	.exposure__info {
		grid-column: 1 / 7;
		margin-bottom: 4rem;
	}
}

.exposure__graph__wrapper {
	display: none;
	grid-column: 1 / 13;
	margin-bottom: 8rem;
}

@media (min-width: 768px) {
	.exposure__graph__wrapper {
		display: block;
	}
}

.exposure__info__title {
	margin-top: 0;
	margin-bottom: 1rem;
	font-size: clamp(2rem, 4vw, 6rem);
	overflow: hidden;
}

.exposure__info__title span {
	display: inline-block;
}

@media (min-width: 768px) {
	.exposure__info__title span {
		transform: translateY(100%);
	}
}

.exposure__description {
	font-size: clamp(1rem, 4vw, 2rem);
}

.exposure__info__description-1 {
	overflow: hidden;
	margin: 0;
}

.exposure__info__description-1 span {
	display: inline-block;
}

@media (min-width: 768px) {
	.exposure__info__description-1 span {
		transform: translateY(100%);
	}
}

.exposure__image__wrapper {
	grid-column: 1 / 13;
}

@media (min-width: 768px) {
	.exposure__image__wrapper {
		grid-column: 1 / 7;
	}
}

.exposure__image {
	width: 100%;
}

.exposure__info__description-2 {
	grid-column: 1 / 13;
	font-size: clamp(1rem, 4vw, 2rem);
}

@media (min-width: 768px) {
	.exposure__info__description-2 {
		grid-column: 7 / 13;
	}
}

.tableauPlaceholder {
	margin-left: auto;
	margin-right: auto;
	width: 1000px;
	height: 827px;
	opacity: 0;
}

#viz1667965304298 object {
	width: 1000px;
	height: 827px;
}

.experimental__image {
	width: 100%;
}

.experimental__wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1rem;
}

.experimental__info {
	grid-column: 1 / 8;
}

@media (min-width: 768px) {
	.experimental__info {
		grid-column: 1 / 8;
	}
}

.experimental__info__title {
	font-size: clamp(2rem, 4vw, 6rem);
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: hidden;
}

.experimental__info__title span {
	display: inline-block;
}

@media (min-width: 768px) {
	.experimental__info__title span {
		transform: translateY(100%);
	}
}

.experimental__info__description__wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
}

.experimental__info__description {
	font-size: clamp(1rem, 4vw, 2rem);
}

.experimental__image__wrapper-1 {
	grid-column: 9 / 13;
}

@media (min-width: 768px) {
	.experimental__image__wrapper-1 {
		margin-bottom: 8rem;
	}
}

.experimental__info__description-1 {
	grid-column: 1 / 13;
	margin: 0;
	overflow: hidden;
}

.experimental__info__description-1 span {
	display: inline-block;
}

@media (min-width: 768px) {
	.experimental__info__description-1 span {
		transform: translateY(100%);
	}
}

.mb-20-percent {
	margin-bottom: 20%;
}

.experimental__info__description-2 {
	display: none;
	grid-column: 4 / 13;
	font-size: clamp(1rem, 4vw, 1.75rem);
}

@media (min-width: 768px) {
	.experimental__info__description-2 {
		display: block;
	}
}

.experimental__info__description-2-mobile-wrapper {
	grid-column: 1 / 13;
	margin-bottom: 3rem;
	font-size: clamp(1rem, 4vw, 1.75rem);
}

@media (min-width: 768px) {
	.experimental__info__description-2-mobile {
		display: none;
	}
}

@media (min-width: 768px) {
	.experimental__info__description-2 {
		grid-column: 4 / 12;
		font-size: clamp(1rem, 4vw, 1.75rem);
	}
}

.experimental__image__wrapper-2 {
	grid-column: 1 / 13;
	margin-bottom: 3rem;
}

@media (min-width: 768px) {
	.experimental__image__wrapper-2 {
		grid-column: 1 / 6;
		margin-bottom: 8rem;
	}
}

.experimental__info__description-3 {
	grid-column: 1 / 13;
	font-size: clamp(1rem, 4vw, 1.75rem);
}

@media (min-width: 768px) {
	.experimental__info__description-3 {
		grid-column: 7 / 13;
		font-size: 1.75rem;
	}
}

.experimental__image--poppy {
	stroke: #191919;
	stroke-width: 4px;
	/* fill: transparent; */
}

.experimental__image--lime {
	stroke: #191919;
	stroke-width: 0.5px;
	fill: transparent;
}

/* ABOUT PAGE STYLES */
.section--about {
	padding-top: 8rem;
	padding-bottom: 4rem;
}

.about {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 2rem;
}

.about__email-signup {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (min-width: 768px) {
	.about__email-signup {
		margin-bottom: initial;
	}
}

.about__info {
	grid-column: span 12;
}

@media (min-width: 768px) {
	.about__info {
		grid-column: span 7;
	}
}

.about__image__wrapper {
	grid-column: span 12;
	place-content: center;
}

@media (min-width: 768px) {
	.about__image__wrapper {
		grid-column: span 5;
	}
}

.about__image {
	width: 100%;
	margin-bottom: 2rem;
}

.about__info__title {
	font-size: clamp(2rem, 4vw, 3.5rem);
	margin-top: 0;
	margin-bottom: 2rem;
}

.about__info__description {
	font-size: clamp(1rem, 4vw, 1.25rem);
	margin-bottom: 1rem;
}

.about_page_email_signup_form .form-group {
	display: flex;
	align-items: center;
}

.about_page_email_signup_form label {
	display: none;
}

.about_page_email_signup_form input {
	font-size: 1.15rem;
	font-family: bilo, sans-serif;
	padding-left: 0.5rem;
	margin-right: 1rem;
	height: 3rem;
	border: none;
	border-radius: 0.25rem;
	width: 100%;
	margin-bottom: 1rem;
}

@media (min-width: 768px) {
	.about_page_email_signup_form input {
		width: 300px;
	}
}

.about_page_email_signup_form input:focus {
	outline: 1px solid rgb(148, 148, 148);
}

.about_page_email_signup_form {
	display: flex;
	flex-direction: column;
}

.about_page_email_signup_form p {
	font-size: 1.25rem;
}

.about_page_email_signup_form .about-submit-button {
	font-size: 1.25rem;
	height: 3rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-left: 1em;
	padding-right: 1em;
	border-radius: 0.25rem;
	border: none;
	background-color: #191919;
	color: #fff;
	font-family: bilo, sans-serif;
	cursor: pointer;
	transition: 0.3s;
	width: 100%;
}

@media (min-width: 768px) {
	.about_page_email_signup_form .about-submit-button {
		width: initial;
	}
}

.about_page_email_signup_form .about-submit-button:hover {
	box-shadow: 4px 4px 4px rgba(25, 25, 25, 0.2);
}

.section--about .supporters {
	grid-column: 1 / 13;
}

.section--about .supporters__list {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1.5rem;
	padding: 0;
}

.section--about .supporters__title {
	grid-column: 1 / 13;
}

.section--about .supporters__list li {
	list-style-type: none;
}

.section--about .supporters__melon {
	grid-column: 1 / 7;
}

.section--about .melon-logo {
	width: 100%;
	height: auto;
}

.section--about .vanderbilt-logo {
	grid-column: 7 / 13;
}

.supporters img,
.supporters svg {
	width: 100%;
	max-width: 200px;
}

.section--about .melon-logo path {
	fill: #191919;
}

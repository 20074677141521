:root {
  --text-color: white;
  --text-dark-color: #202020;
  --header-height: 100px;
  --burger-width: 40px;
  --font-headings: Georgia, serif;
  --bcg-green: #acb7ae;
  --bcg-lightgreen: #a6b3b3;
  --bcg-lightbrown: #b0a7a4;
  --bcg-lightblue: #a3abb1;
  --bcg-loaderblue: #758a94;
  --bcg-loaderbluedark: #566c76;
  --loader-width: 27.36vw;
  --loader-height: 50vh;
  --v-spacing: 10vh;
  --h-gutter: 6.6vw;
  --easing: cubic-bezier(.5, .7, .4, 1);
  --text-dark: #191919;
  --text-light: white;
  --color-text: #191919;
  --color-bg: #f0e3d3;
  --color-link: #191919;
  --color-link-hover: #292929;
  --color-content-title-sub: #191919;
  --cursor-stroke: #191919;
  --cursor-fill: none;
  --cursor-stroke-width: 1px;
}

*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: var(--text-dark-color);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font: 400 18px bilo, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
}

ul, ol {
  list-style-type: none;
}

a {
  color: var(--color-link);
  cursor: pointer;
  outline: none;
}

a:hover, a:focus {
  color: var(--color-link-hover);
  outline: none;
  text-decoration: none;
}

@media (min-width: 768px) {
  section {
    min-height: 100vh;
  }
}

h1 {
  font-family: bely-display, sans-serif;
  font-weight: 400;
}

.grid {
  display: grid;
}

.navbar--home {
  background-color: #0000 !important;
  border-bottom: none !important;
}

.navbar--home .navbar__links {
  justify-content: end;
}

.navbar--home .nav-open {
  font-family: bilo, sans-serif;
  font-size: clamp(1.15rem, 4vw, 1.4rem);
  font-weight: 600;
  position: absolute;
  top: 2rem;
  right: 3rem;
}

.navbar--home .nav-open li:after {
  content: "";
  background-color: #191919;
  width: 100%;
  height: .1rem;
  display: block;
}

.slide-nav {
  background-color: #191919;
  width: 100%;
  height: 100vh;
  font-family: bely-display, sans-serif;
}

.slide-nav__wrapper {
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
  display: grid;
}

.nav-close {
  color: #fff;
  grid-column: 1 / 13;
  place-content: center end;
  margin-right: 10%;
  display: grid;
}

.slide-nav__wrapper .ul {
  grid-column: 3 / 13;
  margin-top: 5%;
  margin-bottom: 5%;
  display: grid;
}

.slide-nav__wrapper .ul li, .slide-nav__wrapper .social li {
  list-style-type: none;
}

.slide-nav__wrapper .ul li {
  margin-bottom: 1.5rem;
  font-size: clamp(2.5rem, 4vw, 4rem);
}

.slide-nav__wrapper li a {
  color: #fff;
  text-decoration: none;
}

.slide-nav__wrapper .slide-nav__links {
  grid-column: 3 / 13;
}

.slide-nav__wrapper .social ul {
  display: flex;
}

.slide-nav__wrapper .social .social-li {
  width: 2rem;
}

.slide-nav__wrapper .social .social-li:first-of-type {
  margin-right: 2rem;
}

.slide-nav__wrapper .supporters {
  grid-column: 1 / 13;
}

.slide-nav__wrapper .supporters__list {
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
  display: grid;
}

.slide-nav__wrapper .supporters__title {
  grid-column: 1 / 13;
}

.slide-nav__wrapper .supporters__list li {
  color: #fff;
  list-style-type: none;
}

.slide-nav__wrapper .supporters__melon {
  grid-column: 1 / 7;
}

@media (min-width: 768px) {
  .slide-nav__wrapper .supporters__melon {
    grid-column: 1 / 5;
  }
}

@media (min-width: 992px) {
  .slide-nav__wrapper .supporters__melon {
    grid-column: 1 / 4;
  }
}

@media (min-width: 1200px) {
  .slide-nav__wrapper .supporters__melon {
    grid-column: 1 / 3;
  }
}

.slide-nav__wrapper .melon-logo {
  width: 100%;
  height: auto;
}

.slide-nav__wrapper .vanderbilt-logo {
  grid-column: 7 / 13;
}

@media (min-width: 768px) {
  .slide-nav__wrapper .vanderbilt-logo {
    grid-column: 5 / 13;
  }
}

@media (min-width: 992px) {
  .slide-nav__wrapper .vanderbilt-logo {
    grid-column: 4 / 13;
  }
}

@media (min-width: 1200px) {
  .slide-nav__wrapper .vanderbilt-logo {
    grid-column: 3 / 13;
  }
}

.slide-nav__wrapper .melon-logo path {
  fill: #fff;
}

.navbar__link {
  font-family: bilo, sans-serif;
  font-weight: 400;
}

.navbar__link--themes {
  display: none;
}

@media (min-width: 768px) {
  .navbar__link--themes {
    display: initial;
  }
}

.slide-nav-open {
  overflow: hidden !important;
}

.slide-nav.menu {
  z-index: 2000;
  background-color: #191919;
  width: 100%;
  height: 100vh;
  font-family: bely-display, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.slide-nav.menu .button {
  color: #fff;
  font-family: bilo, sans-serif;
  font-size: 1.15rem;
}

.slide-nav .bg {
  display: none;
}

@media (min-width: 768px) {
  .slide-nav .bg {
    pointer-events: none;
    width: 250px;
    height: 300px;
    display: block;
    position: absolute;
  }
}

.slide-nav .bg1 {
  opacity: 0;
  background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282304/Website/images/m_home_iakibn.jpg") 50% / cover no-repeat;
  transition: all 1s ease-in-out;
  top: 50px;
  left: 40%;
  transform: translateY(-50%);
}

.slide-nav .bg2 {
  opacity: 0;
  background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282305/Website/images/m_themes_vndnis.jpg") 50% / cover no-repeat;
  transition: all 1s ease-in-out;
  top: 100px;
  left: 40%;
  transform: translateY(-50%);
}

.slide-nav .bg3 {
  opacity: 0;
  background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282297/Website/images/m_about_uza4co.jpg") 50% / cover no-repeat;
  transition: all 1s ease-in-out;
  top: 250px;
  left: 40%;
  transform: translateY(-50%);
}

.slide-nav .bg4 {
  opacity: 0;
  background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282305/Website/images/m_team_qtqhhx.jpg") 50% / cover no-repeat;
  transition: all 1s ease-in-out;
  top: 375px;
  left: 40%;
  transform: translateY(-50%);
}

.slide-nav .bg5 {
  opacity: 0;
  background: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1695076464/logo-o_wzeima.svg") 50% / cover no-repeat;
  transition: all 1s ease-in-out;
  top: 425px;
  left: 40%;
  transform: translateY(-50%);
}

.slide-nav .li1:hover ~ .bg1, .slide-nav .li2:hover ~ .bg2, .slide-nav .li3:hover ~ .bg3, .li4:hover ~ .bg4, .li5:hover ~ .bg5 {
  opacity: 1;
  transition: all 1s ease-in-out;
  transform: translateY(0%);
}

.slide-nav .ul li a:hover {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
  transition: all 1s;
}

.navbar {
  z-index: 1000;
  background-color: #f1e4d5;
  border-bottom: 1px solid #8b8b8b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar .container {
  padding: .5rem;
}

.navbar__links {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar__link {
  cursor: pointer;
  padding: 1rem;
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.container-xl {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.filter {
  opacity: .1;
  pointer-events: none;
  z-index: 20000;
  background-image: url("https://res.cloudinary.com/dv9hlh4du/image/upload/v1668282313/Website/images/noise-bg_sj0tvw.jpg");
  background-repeat: repeat;
  background-size: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.ball {
  border: 1px solid var(--cursor-stroke);
  pointer-events: none;
  z-index: 10000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
}

.js .loading:before, .js .loading:after {
  content: "";
  z-index: 1000;
  position: fixed;
}

.js .loading:before {
  background: var(--color-bg);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.js .loading:after {
  opacity: .4;
  background: var(--color-link);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  animation: .7s linear infinite alternate forwards loaderAnim;
  top: 50%;
  left: 50%;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.cursor {
  display: none;
}

@media screen and (min-width: 53em) {
  .content {
    justify-content: center;
    height: 100%;
    max-height: none;
  }
}

@media (any-pointer: fine) {
  .cursor {
    pointer-events: none;
    z-index: 3000;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }

  .cursor__inner {
    fill: var(--cursor-fill);
    stroke: var(--cursor-stroke);
    stroke-width: var(--cursor-stroke-width);
  }
}

.reveal-gallery {
  color: var(--color-text);
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 72px);
  margin-top: 72px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .reveal-gallery {
    flex-direction: row;
  }
}

.rg__column {
  padding: 0 var(--h-gutter);
  cursor: pointer;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rg__column--inner {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .rg__column--inner {
    display: unset;
    align-items: unset;
    justify-content: unset;
  }
}

.rg__text--heading h3 {
  font-size: 7vw;
  line-height: 1.5;
}

.rg__text--heading span {
  opacity: .5;
  font-size: 5vw;
}

@media (min-width: 768px) {
  .rg__text--heading h3 {
    font-size: 3.6vw;
    line-height: 1.19231;
  }

  .rg__text--heading span {
    opacity: .5;
    font-size: 2vw;
  }
}

.rg__text--heading h3, .rg__text--heading span, .chapter {
  font-family: var(--font-headings);
  font-weight: normal;
}

.rg__text {
  z-index: 1;
  position: relative;
}

@media (min-width: 768px) {
  .rg__text {
    z-index: 1;
    max-width: 17vw;
    position: relative;
  }
}

.rg__text--copy, .stage__text p {
  letter-spacing: .03vw;
  font-weight: 300;
}

.rg__divider {
  background-color: #0003;
  width: 75%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
}

.rg__cta__wrapper--mobile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .rg__cta__wrapper--mobile {
    display: none;
  }
}

.rg__cta__underline--mobile {
  background-color: #00000080;
  width: 50px;
  height: 1px;
}

.rg__cta__text--mobile {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: .15rem;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .rg__divider {
    display: none;
  }

  .rg__column:before {
    content: "";
    background-color: var(--color-text);
    opacity: .1;
    width: 1px;
    height: 20vh;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.rg__image {
  pointer-events: none;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.rg__image img {
  object-fit: cover;
  object-position: 50% 50%;
  opacity: .8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rg__image--mask {
  height: 100%;
  overflow: hidden;
}

.rg__image img {
  opacity: .6;
}

.rg__text--copy {
  position: absolute;
  overflow: hidden;
}

.rg__text--mask {
  overflow: hidden;
}

main {
  z-index: 1;
  position: relative;
}

section {
  position: relative;
}

.with-padding {
  padding: var(--v-spacing) 0;
}

.with-padding-bottom {
  padding-bottom: var(--v-spacing);
}

.chapter {
  left: var(--h-gutter);
  top: var(--v-spacing);
  z-index: 2;
  color: var(--text-color);
  font-size: 1.6vw;
  position: absolute;
}

.chapter span {
  opacity: .4;
}

.section--hero {
  align-items: center;
  display: flex;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.hero {
  align-items: center;
  gap: 2rem;
  min-height: 90vh;
  display: grid;
}

.grid-col-2 {
  grid-template-columns: 1fr 1fr;
}

@media (min-width: 768px) {
  .hero {
    height: 90vh;
  }

  .grid-col-md-2 {
    grid-template-columns: 1fr 1fr;
  }

  .mk-hero__image--mobile {
    display: none;
  }
}

.mk-hero__image {
  display: none;
}

@media (min-width: 768px) {
  .mk-hero__image {
    display: block;
  }
}

.mk-hero__image path:not(.cls-1, .cls-2) {
  stroke-width: 1px;
  stroke: #666;
}

.mk-hero__image .cls-1 {
  stroke-width: 3px;
}

.mk-hero__image .cls-1, .mk-hero__image .cls-2 {
  fill: none;
  stroke: #666;
  stroke-miterlimit: 10;
}

.mk-hero__image .cls-2 {
  stroke-width: 2px;
}

.hero__image {
  justify-content: center;
  max-height: 70vh;
  display: flex;
}

.hero__title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: clamp(3rem, 7.5vw, 8rem);
}

.public-health-map-section {
  display: none;
}

@media (min-width: 768px) {
  .public-health-map-section {
    min-height: unset;
  }
}

.public-health_icon {
  height: 50px;
  margin-right: 1rem;
}

.public-health-section-mobile {
  padding-bottom: 4rem;
}

.public-health-section-mobile .icon_plus {
  fill: #292929;
  height: 1.25rem;
}

.public-health-section-mobile .locations_list li {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.public-health-section-mobile .locations_list li > div:nth-child(2) {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.public-health-hero {
  height: 40vh;
  min-height: 350px;
  margin-top: 6rem;
}

.divider {
  background-color: #9e9797;
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .public-health-hero {
    min-height: inital;
    height: initial;
    margin-top: 0;
  }

  .public-health-map-section {
    display: block;
  }

  .public-health-section-mobile {
    display: none;
  }
}

.public-health-hero-description {
  margin-left: 0 !important;
}

.hero__description {
  text-align: center;
  margin-left: 3rem;
  font-size: clamp(1rem, 2.3vw, 1.5rem);
}

.mk-hero-title-span {
  margin-left: 3rem;
}

.year {
  grid-template-columns: 1fr;
  gap: 2rem;
  display: grid;
}

@media (min-width: 768px) {
  .year {
    grid-template-columns: 1fr 1fr;
  }

  .year--1854 {
    grid-template-columns: 1fr 3fr;
  }
}

@media (min-width: 1200px) {
  .year--1854 {
    grid-template-columns: 1fr 1fr;
  }
}

.section--year-num-1856 .year-text {
  order: 1;
}

.section--year-num-1856 .year-num-1956 {
  order: 0;
}

@media (min-width: 768px) {
  .section--year-num-1856 .year-text {
    order: 0;
  }

  .section--year-num-1856 .year-num-1956 {
    order: 1;
  }

  .year--1856 {
    grid-template-columns: 2fr 1fr;
  }
}

@media (min-width: 1200px) {
  .year--1856 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .year--1867 {
    grid-template-columns: 1fr 3fr;
  }
}

@media (min-width: 1200px) {
  .year--1867 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .year--1871 {
    grid-template-columns: 1fr 3fr;
  }
}

@media (min-width: 1200px) {
  .year--1871 {
    grid-template-columns: 1fr 1fr;
  }
}

.year-num__stroke {
  color: #0000;
  -webkit-text-stroke: 2px #191919;
  margin: 0;
  font-size: clamp(7rem, 14vw, 12rem);
}

@media (min-width: 768px) {
  .year-num__stroke {
    color: #0000;
    -webkit-text-stroke: 2px #191919;
    margin: -4rem 0 0;
  }

  .year-num__stroke.right {
    text-align: right;
  }
}

.year-text {
  font-size: clamp(1rem, 4vw, 1.5rem);
}

.year__image img {
  width: 100%;
}

.timeline__quote {
  text-align: center;
  margin-bottom: 0;
  font-family: bely, sans-serif;
  font-size: clamp(1.2rem, 4vw, 4rem);
  font-style: italic;
  font-weight: 600;
}

.timeline__quote__person {
  text-align: center;
  font-family: bely, sans-serif;
  font-size: clamp(1.2rem, 4vw, 3rem);
  font-style: italic;
  font-weight: 600;
}

@media (min-width: 768px) {
  .timeline__quote-wrapper {
    min-height: 100vh;
  }
}

.section--scrolling-headers {
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 1rem;
  font-style: italic;
  display: flex;
}

@media (min-width: 768px) {
  .section--scrolling-headers {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.timeline__scrolling-header:first-of-type {
  font-size: 2.2rem;
}

.timeline__scrolling-header:nth-of-type(2) {
  font-size: 1.184rem;
}

@media (min-width: 768px) {
  .section--scrolling-headers {
    padding: 0;
    font-style: italic;
    overflow-x: hidden;
  }

  .scrolling-headers__wrapper {
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    display: flex;
  }

  .timeline__scrolling-header {
    white-space: nowrap;
  }

  .timeline__scrolling-header:first-of-type {
    margin: 0;
    font-size: 8rem;
    transform: translate3d(100%, 0, 0);
  }

  .timeline__scrolling-header:nth-of-type(2) {
    margin: 0;
    font-size: 3.158rem;
    transform: translate3d(-200%, 0, 0);
  }
}

@media (min-width: 992px) {
  .timeline__scrolling-header:first-of-type {
    font-size: 11rem;
  }

  .timeline__scrolling-header:nth-of-type(2) {
    font-size: 4.342rem;
  }
}

@media (min-width: 1200px) {
  .timeline__scrolling-header:first-of-type {
    font-size: 14rem;
  }

  .timeline__scrolling-header:nth-of-type(2) {
    font-size: 526.5rem;
  }
}

@media (min-width: 1400px) {
  .timeline__scrolling-header:first-of-type {
    font-size: 19rem;
  }

  .timeline__scrolling-header:nth-of-type(2) {
    font-size: 7.5rem;
  }
}

.section--timeline__quote {
  margin-bottom: 8rem;
}

@media (min-width: 768px) {
  .section--timeline__quote {
    margin-bottom: unset;
    min-height: 100vh;
    padding-top: 8rem;
    position: relative;
  }
}

.section--timeline__quote--pai-manoel .timeline__quote, .section--timeline__quote--diseases-war .timeline__quote {
  font-size: clamp(2rem, 5vw, 5.5rem);
}

.section--timeline__quote .open-quote {
  width: 12vw;
  margin-bottom: 3vw;
  margin-right: auto;
  display: block;
}

.section--timeline__quote .close-quote {
  width: 12vw;
  margin-top: 3vw;
  margin-left: auto;
  display: block;
}

.recife-map {
  z-index: 1;
  width: 100%;
}

.ph-location {
  fill: #f17c57fa;
}

.ph-location__group {
  cursor: pointer;
  position: relative;
}

.ph-location--health-council {
  transform: translate(57.4%, 66.5%);
}

.ph-location--hospital-lazaros {
  transform: translate(96%, 59%);
}

.ph-location--cemetary {
  transform: translate(74.75%, 46.5%);
}

.ph-location--nogueira {
  transform: translate(8.25%, 73%);
}

.ph-location--port {
  transform: translate(57%, 76%);
}

.pulse {
  animation: 2s infinite pulse-animation;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.section--contact {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.section--contact .contact__header {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .section--contact .contact__header {
    margin-bottom: 4rem;
  }
}

.section--contact .contact__header h1 {
  margin-bottom: 0;
  font-size: clamp(3rem, 6vw, 6rem);
}

.section--contact .contact__header p {
  font-size: clamp(1.2rem, 2.5vw, 1.75rem);
}

.section--contact .contact {
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  display: grid;
}

.section--contact .contact__header {
  grid-column: 1 / 13;
}

@media (min-width: 768px) {
  .section--contact .contact__header {
    grid-column: 1 / 8;
  }
}

.section--contact .contact__info {
  grid-column: 1 / 13;
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .section--contact .contact__info {
    grid-column: 1 / 6;
    margin-bottom: 0;
  }
}

.section--contact .contact__info h2 {
  margin-top: 0;
}

.section--contact .contact__form {
  grid-column: 1 / 13;
}

@media (min-width: 992px) {
  .section--contact .contact__form {
    grid-column: 7 / 13;
  }
}

.form-control input, .form-control textarea {
  border: none;
  border-radius: .25rem;
  width: 100%;
  min-height: 3.5rem;
  margin-top: .5rem;
  padding: .75rem;
  font-size: 1.15rem;
}

.form-control input:focus, .form-control textarea:focus {
  outline: 1px solid #f1933e;
}

.form-control:not(.form-control:last-child) {
  margin-bottom: 1.5rem;
}

.contact__form .btn--submit {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #191919;
  border-radius: .25rem;
  width: 100%;
  height: 3.5rem;
  padding: .75em 2em;
  font-size: 1.2rem;
  transition: all .3s;
}

@media (min-width: 768px) {
  .contact__form .btn--submit {
    width: initial;
  }
}

.contact__form .btn--submit:hover, .contact__form .btn--submit:focus {
  box-shadow: 4px 4px 4px #19191933;
}

.form-control .feedback {
  padding: .5rem .5rem .5rem 0;
  display: none;
}

.form-control.error .feedback {
  display: block;
}

.form-control.error input, .form-control.error textarea {
  outline: 1px solid #ce0101;
}

.form-control.error .feedback {
  color: #ce0101;
}

.form-control label {
  margin-bottom: .5rem;
}

.form-control--notes {
  display: none;
}

.form-message.success {
  color: #198754;
  margin-top: .5rem;
  padding-left: .5rem;
  font-weight: bold;
}

.contact__form .form-message {
  margin-bottom: 1.5rem;
}

.contact__social img {
  width: 32px;
}

.contact__email a {
  font-size: 1.2rem;
  text-decoration: none;
}

.contact__social a:first-child {
  margin-right: 1.25rem;
}

.section--team {
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.section--team .team__header {
  margin-bottom: 4rem;
}

.section--team .team__header h1 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: clamp(3rem, 6vw, 6rem);
}

.section--team .team__header p {
  margin-top: 0;
  font-size: clamp(1.2rem, 2.5vw, 1.75rem);
}

.section--team .team__member h2 {
  margin: 0;
  font-size: clamp(1.25rem, 5vw, 3rem);
}

.section--team .team__member p {
  margin-top: .25rem;
  margin-bottom: .25rem;
  margin-left: 1rem;
  font-size: clamp(1rem, 5vw, 1.5rem);
  font-style: italic;
}

.section--team .team__member h3 {
  margin-top: 0;
  margin-right: 1rem;
  padding-left: 1rem;
  font-size: clamp(1rem, 5vw, 2rem);
  display: inline-block;
}

.section--team .team__member {
  margin-bottom: 2rem;
}

.section--team .team {
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  display: grid;
}

.section--team .team__header {
  grid-column: 1 / 13;
}

@media (min-width: 768px) {
  .section--team .team__header {
    grid-column: 1 / 8;
  }
}

.section--team .team__members {
  grid-column: 1 / 13;
}

.member__links {
  display: inline-block;
}

.member__links a {
  padding: 1rem;
}

.member__links img {
  width: 100%;
  max-width: 24px;
  display: inline-block;
}

.modal-open {
  overflow: hidden;
}

#modalBackdrop {
  z-index: 9998;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#modal {
  z-index: 9999;
  background-color: #fafafa;
  width: 90%;
  min-width: 300px;
  max-width: 600px;
  height: 100%;
  transition: right .3s ease-out;
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  overflow-y: auto;
  box-shadow: -4px 0 24px #1919191a;
}

#modalContent {
  margin-top: 2.5rem;
  padding: 1rem;
}

#closeButton {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.modal_image {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  display: none;
}

.modal_title {
  text-align: center;
  margin-bottom: .5rem;
  font-size: clamp(2rem, 4vw, 3rem);
}

.modal_text p {
  font-size: clamp(1rem, 2vw, 1.25rem) !important;
}

.modal_text p:not(:last-child) {
  margin-bottom: 1rem;
}

.slide-info__close-wrapper {
  text-align: right;
  padding: 1rem 1rem 2rem;
}

.slide-info .close {
  color: #000;
  padding: 1rem;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.slide-info {
  z-index: 2000;
  background-color: #fafafa;
  width: 90%;
  min-width: 300px;
  max-width: 600px;
  height: 100vh;
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: scroll;
  transform: translateX(120%);
  box-shadow: -4px 0 24px #1919191a;
}

.slide-info__image {
  width: 100%;
  margin-bottom: 1rem;
}

.slide-info__title {
  text-align: center;
  font-size: clamp(2rem, 4vw, 3rem);
}

.fill--transparent {
  fill: #0000;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-3 {
  margin-top: 1rem;
}

.theme__hero {
  padding-top: 8rem;
}

@media (min-width: 768px) {
  .theme__hero {
    padding-top: unset;
  }
}

.year-image {
  width: 100%;
}

.year-image__wrapper figure {
  margin: 0;
}

.year-image__wrapper figcaption {
  font-size: 1.25rem;
}

.italic {
  font-style: italic;
}

.text-small {
  font-size: 1rem;
}

.year-text p:last-of-type {
  margin-bottom: 3rem;
}

.theme-lead-in__link {
  text-decoration: none;
}

.theme-lead-in__wrapper {
  opacity: 0;
  width: 100%;
  margin-bottom: 4rem;
}

.theme-lead-in {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.theme-lead-in__image {
  width: 100%;
}

.theme-lead-in__next {
  opacity: 0;
  text-align: center;
  margin-top: 0;
  font-family: bely-display, sans-serif;
  font-size: clamp(1rem, 4vw, 2rem);
}

.cholera-hero-section {
  align-items: center;
  margin-bottom: 4rem;
  display: flex;
}

@media (min-width: 768px) {
  .cholera-hero-section {
    margin-bottom: intial;
  }
}

.cholera {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  display: grid;
}

.cholera__image__wrapper figure {
  text-align: center;
  margin: 0;
}

.cholera__image {
  width: 100%;
}

.cholera__text {
  font-size: 1.5rem;
}

.cholera__text h2 {
  margin-top: 0;
}

.cholera__text p:last-of-type {
  margin-bottom: 3rem;
}

.hero__image--cholera {
  max-width: 500px;
}

.impact__image {
  width: 100%;
}

.impact__wrapper {
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  display: grid;
}

.impact__info {
  grid-column: 1 / 13;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .impact__info {
    grid-column: 1 / 8;
    margin-bottom: 3rem;
  }
}

.impact__info__title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: clamp(2rem, 4vw, 6rem);
  overflow: hidden;
}

.impact__info__title span {
  display: inline-block;
}

@media (min-width: 768px) {
  .impact__info__title span {
    transform: translateY(100%);
  }
}

.impact__info__description {
  font-size: clamp(1rem, 4vw, 2rem);
}

.impact__image__wrapper-1 {
  grid-column: 1 / 13;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .impact__image__wrapper-1 {
    grid-column: 1 / 10;
    margin-bottom: 8rem;
    overflow: hidden;
  }
}

.impact__info__description-2 {
  grid-column: 1 / 13;
}

@media (min-width: 768px) {
  .impact__info__description-2 {
    grid-column: 1 / 7;
    padding-top: 10%;
  }
}

.impact__image__wrapper-2 {
  grid-column: 1 / 13;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .impact__image__wrapper-2 {
    grid-column: 8 / 13;
    margin-bottom: 8rem;
    overflow: hidden;
  }
}

.impact__info__description-3 {
  text-align: center;
  grid-column: 1 / 13;
  margin: 0;
  font-size: clamp(1.25rem, 4vw, 2rem);
  line-height: 2rem;
  overflow: hidden;
}

@media (min-width: 768px) {
  .impact__info__description-3 {
    grid-column: 3 / 11;
  }
}

.impact__info__description-3 span {
  display: inline-block;
}

@media (min-width: 768px) {
  .impact__info__description-3 span {
    transform: translateY(100%);
  }
}

.impact__info__description-1 {
  margin: 0;
  overflow: hidden;
}

.impact__info__description-1 span {
  display: inline-block;
}

@media (min-width: 768px) {
  .impact__info__description-1 span {
    transform: translateY(100%);
  }
}

.transmission__wrapper {
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  display: grid;
}

.transmission__info {
  grid-column: 1 / 13;
}

@media (min-width: 768px) {
  .transmission__info {
    grid-column: 7 / 13;
  }
}

.transmission__image__wrapper {
  grid-column: 1 / 13;
}

@media (min-width: 768px) {
  .transmission__image__wrapper {
    grid-column: 1 / 6;
    margin-top: -10rem;
    overflow: hidden;
  }
}

.transmission__info__title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: clamp(2rem, 4vw, 6rem);
  overflow: hidden;
}

.transmission__info__title span {
  display: inline-block;
}

@media (min-width: 768px) {
  .transmission__info__title span {
    transform: translateY(100%);
  }
}

.transmission__info__description {
  font-size: clamp(1rem, 4vw, 2rem);
}

.transmission__info__description-1 {
  margin: 0;
  overflow: hidden;
}

.transmission__info__description-1 span {
  display: inline-block;
}

@media (min-width: 768px) {
  .transmission__info__description-1 span {
    transform: translateY(100%);
  }
}

.transmission__image {
  width: 100%;
}

.transmission__description-2 {
  grid-column: 1 / 13;
  margin: 0;
  font-size: clamp(1rem, 4vw, 2rem);
  overflow: hidden;
}

@media (min-width: 768px) {
  .transmission__description-2 {
    grid-column: 7 / 13;
  }
}

.transmission__description-2 span {
  display: inline-block;
}

@media (min-width: 768px) {
  .transmission__description-2 span {
    transform: translateY(100%);
  }
}

.transmission__wrapper-2 {
  gap: 0;
}

.cholera-reaper__wrapper {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: none;
  overflow: hidden;
}

@media (min-width: 768px) {
  .cholera-reaper__wrapper {
    display: block;
  }
}

.cholera-reaper__image {
  width: 700px;
  transform: translateX(-100%);
}

.exposure__wrapper {
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  display: grid;
}

.exposure__info {
  grid-column: 1 / 13;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .exposure__info {
    grid-column: 1 / 7;
    margin-bottom: 4rem;
  }
}

.exposure__graph__wrapper {
  grid-column: 1 / 13;
  margin-bottom: 8rem;
  display: none;
}

@media (min-width: 768px) {
  .exposure__graph__wrapper {
    display: block;
  }
}

.exposure__info__title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: clamp(2rem, 4vw, 6rem);
  overflow: hidden;
}

.exposure__info__title span {
  display: inline-block;
}

@media (min-width: 768px) {
  .exposure__info__title span {
    transform: translateY(100%);
  }
}

.exposure__description {
  font-size: clamp(1rem, 4vw, 2rem);
}

.exposure__info__description-1 {
  margin: 0;
  overflow: hidden;
}

.exposure__info__description-1 span {
  display: inline-block;
}

@media (min-width: 768px) {
  .exposure__info__description-1 span {
    transform: translateY(100%);
  }
}

.exposure__image__wrapper {
  grid-column: 1 / 13;
}

@media (min-width: 768px) {
  .exposure__image__wrapper {
    grid-column: 1 / 7;
  }
}

.exposure__image {
  width: 100%;
}

.exposure__info__description-2 {
  grid-column: 1 / 13;
  font-size: clamp(1rem, 4vw, 2rem);
}

@media (min-width: 768px) {
  .exposure__info__description-2 {
    grid-column: 7 / 13;
  }
}

.tableauPlaceholder {
  opacity: 0;
  width: 1000px;
  height: 827px;
  margin-left: auto;
  margin-right: auto;
}

#viz1667965304298 object {
  width: 1000px;
  height: 827px;
}

.experimental__image {
  width: 100%;
}

.experimental__wrapper {
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  display: grid;
}

.experimental__info {
  grid-column: 1 / 8;
}

@media (min-width: 768px) {
  .experimental__info {
    grid-column: 1 / 8;
  }
}

.experimental__info__title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: clamp(2rem, 4vw, 6rem);
  overflow: hidden;
}

.experimental__info__title span {
  display: inline-block;
}

@media (min-width: 768px) {
  .experimental__info__title span {
    transform: translateY(100%);
  }
}

.experimental__info__description__wrapper {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}

.experimental__info__description {
  font-size: clamp(1rem, 4vw, 2rem);
}

.experimental__image__wrapper-1 {
  grid-column: 9 / 13;
}

@media (min-width: 768px) {
  .experimental__image__wrapper-1 {
    margin-bottom: 8rem;
  }
}

.experimental__info__description-1 {
  grid-column: 1 / 13;
  margin: 0;
  overflow: hidden;
}

.experimental__info__description-1 span {
  display: inline-block;
}

@media (min-width: 768px) {
  .experimental__info__description-1 span {
    transform: translateY(100%);
  }
}

.mb-20-percent {
  margin-bottom: 20%;
}

.experimental__info__description-2 {
  grid-column: 4 / 13;
  font-size: clamp(1rem, 4vw, 1.75rem);
  display: none;
}

@media (min-width: 768px) {
  .experimental__info__description-2 {
    display: block;
  }
}

.experimental__info__description-2-mobile-wrapper {
  grid-column: 1 / 13;
  margin-bottom: 3rem;
  font-size: clamp(1rem, 4vw, 1.75rem);
}

@media (min-width: 768px) {
  .experimental__info__description-2-mobile {
    display: none;
  }

  .experimental__info__description-2 {
    grid-column: 4 / 12;
    font-size: clamp(1rem, 4vw, 1.75rem);
  }
}

.experimental__image__wrapper-2 {
  grid-column: 1 / 13;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .experimental__image__wrapper-2 {
    grid-column: 1 / 6;
    margin-bottom: 8rem;
  }
}

.experimental__info__description-3 {
  grid-column: 1 / 13;
  font-size: clamp(1rem, 4vw, 1.75rem);
}

@media (min-width: 768px) {
  .experimental__info__description-3 {
    grid-column: 7 / 13;
    font-size: 1.75rem;
  }
}

.experimental__image--poppy {
  stroke: #191919;
  stroke-width: 4px;
}

.experimental__image--lime {
  stroke: #191919;
  stroke-width: .5px;
  fill: #0000;
}

.section--about {
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.about {
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
  display: grid;
}

.about__email-signup {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .about__email-signup {
    margin-bottom: initial;
  }
}

.about__info {
  grid-column: span 12;
}

@media (min-width: 768px) {
  .about__info {
    grid-column: span 7;
  }
}

.about__image__wrapper {
  grid-column: span 12;
  place-content: center;
}

@media (min-width: 768px) {
  .about__image__wrapper {
    grid-column: span 5;
  }
}

.about__image {
  width: 100%;
  margin-bottom: 2rem;
}

.about__info__title {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: clamp(2rem, 4vw, 3.5rem);
}

.about__info__description {
  margin-bottom: 1rem;
  font-size: clamp(1rem, 4vw, 1.25rem);
}

.about_page_email_signup_form .form-group {
  align-items: center;
  display: flex;
}

.about_page_email_signup_form label {
  display: none;
}

.about_page_email_signup_form input {
  border: none;
  border-radius: .25rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding-left: .5rem;
  font-family: bilo, sans-serif;
  font-size: 1.15rem;
}

@media (min-width: 768px) {
  .about_page_email_signup_form input {
    width: 300px;
  }
}

.about_page_email_signup_form input:focus {
  outline: 1px solid #949494;
}

.about_page_email_signup_form {
  flex-direction: column;
  display: flex;
}

.about_page_email_signup_form p {
  font-size: 1.25rem;
}

.about_page_email_signup_form .about-submit-button {
  color: #fff;
  cursor: pointer;
  background-color: #191919;
  border: none;
  border-radius: .25rem;
  width: 100%;
  height: 3rem;
  padding: .5em 1em;
  font-family: bilo, sans-serif;
  font-size: 1.25rem;
  transition: all .3s;
}

@media (min-width: 768px) {
  .about_page_email_signup_form .about-submit-button {
    width: initial;
  }
}

.about_page_email_signup_form .about-submit-button:hover {
  box-shadow: 4px 4px 4px #19191933;
}

.section--about .supporters {
  grid-column: 1 / 13;
}

.section--about .supporters__list {
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
  padding: 0;
  display: grid;
}

.section--about .supporters__title {
  grid-column: 1 / 13;
}

.section--about .supporters__list li {
  list-style-type: none;
}

.section--about .supporters__melon {
  grid-column: 1 / 7;
}

.section--about .melon-logo {
  width: 100%;
  height: auto;
}

.section--about .vanderbilt-logo {
  grid-column: 7 / 13;
}

.supporters img, .supporters svg {
  width: 100%;
  max-width: 200px;
}

.section--about .melon-logo path {
  fill: #191919;
}

/*# sourceMappingURL=contact.50f47bd0.css.map */
